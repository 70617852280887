import Link from 'next/link';
import { useRouter } from 'next/router'
import s from './Navbar.module.scss';
import { GrClose } from "react-icons/gr";
import Social from "../Social/Social";

import { useEffect, useState } from 'react';

export default function Navbar({ hasNews }) {
    const router = useRouter();
    let currentRoute = router.asPath;
    const [currentOffset, setCurrentOffset] = useState(0);

    const toggleMenu = () => {
        const menu = document.querySelector("#mobile-menu");
        console.log('menu', menu);
        menu.classList.toggle(s.open);
    }

    useEffect(() => {

    }, [currentRoute]);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setCurrentOffset(window.scrollY);
        })
    }, []);

    // There is probably a better way to apply dynamic styles xd

    return (
        <nav id="navbar" className={`navbar sticky-top navbar-expand-md ${hasNews ? s.cintilla : ''}
            ${currentRoute != "/" && currentRoute != "/servicios"
                ? `${s.bg__black} ${s.text__white}`
                : currentRoute == "/servicios"
                    ? currentOffset > 100 ? `${s.bg__black} ${s.text__white}` : `${s.bg__transparent} ${s.text__white}`
                    : currentOffset > 100 ? `${s.bg__black} ${s.text__white}` : `${s.bg__transparent} ${s.text__white}`
            }`
        } style={{
            height: '5rem',
        }}>
            <div className={`container`}>
                <div className={s.desktop__nav}>
                    <Link href='/'>
                        <img id='navbar-logo' src={
                            currentRoute != "/" && currentRoute != "/servicios"
                                ? "/logo.png"
                                : "/logo.png"
                                   
                        } alt="WIMB-logo" width={230 / 2} height={75 / 2} aria-label='inicio' />
                    </Link>
                    <div className={s.link__container}>
                        <ul className={`fw-bold`}>
                            <li className={s.item}>
                                <Link href='/' >
                                    <a aria-label='inicio'>INICIO</a>
                                </Link>
                            </li>
                            <li className={s.item}>
                                <Link href='/nosotros'>
                                    <a aria-label='nosotros'>NOSOTROS</a>
                                </Link>
                            </li>
                            <li className={s.item}>
                                <Link href='/servicios'>
                                    <a aria-label='servicios'>SERVICIOS</a>
                                </Link>
                            </li>
                            <li className={s.item}>
                                <Link href='/portafolio'>
                                    <a aria-label='portafolio'>PORTAFOLIO</a>
                                </Link>
                            </li>
                            <li className={s.item}>
                                <a href='https://blog.whereismybrand.com.mx' target={'_blank'} rel={'noreferrer'} aria-label='blog'>BLOG</a>
                            </li>
                            <li className={`${s.btn__item} orange rounded p-2`}>
                                <Link href='/contacto'>
                                    <a aria-label='contacto' className={`hover-black`}>CONTACTO</a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={s.mobile__nav}>
                    <Link href='/'>
                        <img id='navbar-logo' src={
                            currentRoute != "/" && currentRoute != "/servicios"
                                ? "/logo.png"
                                : "/logo.png"
                        } alt="WIMB-logo" width={230 / 2} height={75 / 2} aria-label='inicio' />
                    </Link>
                    <button className={s.toggle__btn} onClick={toggleMenu}>
                        <img src='/svg/hamburguer.svg' alt="burguer" className={s.toggle}></img>
                    </button>
                    <div id='mobile-menu' className={s.sidebar}>
                        <Link href='/'>
                            <img alt="logo" className={s.iso} />
                        </Link>
                        <GrClose width={32} height={32} className={s.close} onClick={toggleMenu} />
                        <div className="d-flex flex-column mt-5 pt-5 ps-3">
                            <Link href='/'>
                                <p role={"link"} onClick={toggleMenu} className={`fs-header fw-bold mb-1 ${currentRoute == '/' ? "text-orange" : ""}`} aria-current="page">INICIO</p>
                            </Link>
                            <Link href='/nosotros'>
                                <p role={"link"} onClick={toggleMenu} className={`fs-header fw-bold mb-1 ${currentRoute == '/nosotros' ? "text-orange" : ""}`} aria-current="page">NOSOTROS</p>
                            </Link>
                            <Link href='/servicios'>
                                <p role={"link"} onClick={toggleMenu} className={`fs-header fw-bold mb-1 ${currentRoute == '/servicios' ? "text-orange" : ""}`} aria-current="page">SERVICIOS</p>
                            </Link>
                            <Link href='/portafolio'>
                                <p role={"link"} onClick={toggleMenu} className={`fs-header fw-bold mb-1 ${currentRoute == '/portafolio' ? "text-orange" : ""}`} aria-current="page">PORTAFOLIO</p>
                            </Link>
                            <a href='https://blog.whereismybrand.com.mx' target={'_blank'} rel={'noreferrer'} aria-label='blog' className={`${s.anchor} fs-header fw-bold mb-1 ${currentRoute == '/portafolio' ? "text-orange" : ""}`} aria-current="page">BLOG</a>
                            <Link href='/contacto'>
                                <p role={"link"} onClick={toggleMenu} className={`fs-header fw-bold mb-1 ${currentRoute == '/contacto' ? "text-orange" : ""}`} aria-current="page">CONTACTO</p>
                            </Link>
                        </div>
                        <p className="mt-5 ps-3 fs-5">
                            Tel: 477 309 1387
                        </p>
                        <div className="mt-4 ps-3">
                            <Social />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}